import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import translation hook

const TotalRegisteredPlayersToday = ({ count, sx }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(); // Initialize translation

  const getTodayDate = () => {
    const now = new Date();
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0));
    return today.toISOString().split('.')[0].replace('T', ' ');
  };

  const handleCardClick = () => {
    history.push({
      pathname: '/players',
      state: {
        filter: {
          date: {
            from: getTodayDate(),
            to: new Date(Date.now() + 86400000).toISOString().split('.')[0].replace('T', ' '),
          },
        },
      },
    });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        ...sx,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <GroupAddIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box
            sx={{
              ...(i18n.language === 'ar' ? { mr: 2 } : { ml: 2 }), // Adjust spacing for RTL/LTR
            }}
          >
            <Typography variant="h6">{t('total_registered_players_today.title')}</Typography> {/* Translate title */}
            <Typography variant="h4">{count}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalRegisteredPlayersToday;
