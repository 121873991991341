import React, { useState } from 'react';
import { Box, Modal, Typography, IconButton, Button, useTheme } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // New icon for Money Transfer
import CloseIcon from '@mui/icons-material/Close';
import NewPlayerModal from '../players/NewPlayerModal';
import NewPlayerFormModal from '../players/NewPlayerFormModal';
import MoneyTransferModal from '../players/MoneyTransferModal';
import api from '../../services/api';
import { useTranslation } from 'react-i18next'; // Import translation hook

const AddModal = ({ open, handleClose, fetchBalance, formatCurrency }) => {
  const theme = useTheme();
  const { t } = useTranslation(); // Initialize translation

  const [openNewPlayerModal, setOpenNewPlayerModal] = useState(false);
  const [openNewPlayerFormModal, setOpenNewPlayerFormModal] = useState(false);
  const [openMoneyTransferModal, setOpenMoneyTransferModal] = useState(false);
  const [newPlayer, setNewPlayer] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSetNewPlayer = (player) => {
    setNewPlayer(player);
    setLoading(false);
    setOpenNewPlayerModal(true);
  };

  const handleCloseNewPlayerModal = () => {
    setOpenNewPlayerModal(false);
    setNewPlayer(null);
  };

  const handleFastCreate = async () => {
    handleClose();
    setOpenNewPlayerModal(true);
    setLoading(true);
    try {
      const response = await api.post('/players/create/fast', {});
      const data = await response.data;
      if (data.status === 'success') {
        handleSetNewPlayer(data.player);
      } else {
        console.error(t('error.fastCreateFailed'));
        handleSetNewPlayer(null);
      }
    } catch (error) {
      console.error(t('error.fastCreateError'), error);
      handleSetNewPlayer(null);
    }
  };

  const handleOpenNewPlayerFormModal = () => {
    handleClose();
    setOpenNewPlayerFormModal(true);
  };

  const handleCloseNewPlayerFormModal = () => {
    setOpenNewPlayerFormModal(false);
  };

  const handleOpenMoneyTransferModal = () => {
    handleClose();
    setOpenMoneyTransferModal(true);
  };

  const handleCloseMoneyTransferModal = () => {
    setOpenMoneyTransferModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            bgcolor: theme.palette.background.paper,
            boxShadow: theme.shadows[24],
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('addModal.title')} {/* Translate "Select an Option" */}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Button
            onClick={handleOpenMoneyTransferModal}
            startIcon={<MonetizationOnIcon  />} // New icon for Money Transfer
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center icon and text
              alignItems: 'center', // Align icon and text vertically
              gap: 1, // Add gap between icon and text
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {t('addModal.moneyTransfer')} {/* Translate "Money Transfer" */}
          </Button>
          <Button
            onClick={handleOpenNewPlayerFormModal}
            startIcon={<PersonAddIcon />}
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center icon and text
              alignItems: 'center', // Align icon and text vertically
              gap: 1, // Add gap between icon and text
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {t('addModal.newPlayer')} {/* Translate "New Player" */}
          </Button>
          <Box mt={2}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              {t('addModal.close')} {/* Translate "Close" */}
            </Button>
          </Box>
        </Box>
      </Modal>
      <NewPlayerFormModal
        open={openNewPlayerFormModal}
        handleClose={handleCloseNewPlayerFormModal}
        handleSetNewPlayer={handleSetNewPlayer}
      />
      <NewPlayerModal
        open={openNewPlayerModal}
        handleClose={handleCloseNewPlayerModal}
        playerData={newPlayer}
        loading={loading}
        fetchBalance={fetchBalance}
      />
      <MoneyTransferModal
        open={openMoneyTransferModal}
        handleClose={handleCloseMoneyTransferModal}
        setOpenMoneyTransferModal={setOpenMoneyTransferModal}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
        initialUsername={false}
      />
    </>
  );
};

export default AddModal;
