import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Layout from '../shared/Layout';
import api from '../../services/api';
import DynamicListComponent from '../shared/DynamicListComponent';
import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
  Snackbar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const BlockedPlayers = () => {
  const { user } = useContext(AuthContext);
  const [balance, setBalance] = useState(user ? user.balance : null);
  const [players, setPlayers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [sortConfig, setSortConfig] = useState({ title: null, status: 'asc' });
  const [formFilters, setFormFilters] = useState({
    username: '',
    playerId: '',
    dateFrom: '',
    dateTo: ''
  });
  const [apiFilters, setApiFilters] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (isPortrait) {
      setFiltersVisible(false);
    }
  }, [isPortrait]);

  useEffect(() => {
    fetchPlayers(apiFilters, sortConfig);
  }, [apiFilters, sortConfig, currentPage, pageSize]);

  const fetchPlayers = async (filters, sortConfig) => {
    try {
      const response = await api.post(
        '/players/blockedlist',
        {
          start: (currentPage - 1) * pageSize,
          limit: pageSize,
          filter: Object.keys(filters).length > 0 ? filters : {},
          sort: sortConfig.title ? sortConfig : undefined
        }
      );
      setPlayers(response.data.result.records);
      setTotalRecords(response.data.result.totalRecordsCount);
    } catch (error) {
      console.error('Error fetching blocked players:', error);
    }
  };

  const handleUnblockPlayer = async (playerId) => {
    try {
      const response = await api.post('/players/unblock', { playerId });
      if (response.data.message === 'Player unblocked successfully') {
        setSnackbarMessage('Player unblocked successfully');
        fetchPlayers(apiFilters, sortConfig); // Refresh the list after unblocking
      } else {
        setSnackbarMessage('Failed to unblock player');
      }
    } catch (error) {
      setSnackbarMessage('Error unblocking player');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      [name]: value
    }));
  };

  const handleDateChange = (name, value) => {
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      [name]: value
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const cleanedFilters = cleanFilters(formFilters);
    setApiFilters(cleanedFilters);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setFormFilters({
      username: '',
      playerId: '',
      dateFrom: '',
      dateTo: ''
    });
    setApiFilters({});
    setCurrentPage(1);
  };

  const cleanFilters = (filters) => {
    const cleanedFilters = {};
    if (filters.username) {
      cleanedFilters.userName = { action: 'like', value: filters.username, valueLabel: filters.username };
    }
    if (filters.playerId) {
      cleanedFilters.playerId = { action: '=', value: filters.playerId, valueLabel: filters.playerId };
    }
    if (filters.dateFrom && filters.dateTo) {
      cleanedFilters.date = {
        from: filters.dateFrom,
        to: filters.dateTo,
        action: 'between',
        valueLabel: `${filters.dateFrom} - ${filters.dateTo}`
      };
    }
    return cleanedFilters;
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(totalRecords / pageSize)) {
      setCurrentPage(pageNumber);
      setGoToPage('');
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const columns = [
    { key: 'playerId', label: 'Player ID' },
    { key: 'userName', label: 'Username' },
    { key: 'date', label: 'Blocked Date' },
    { key: 'deleteBy', label: 'Deleted By' },
    {
      key: 'unblock',
      label: 'Unblock',
      render: (row) => (
        <IconButton onClick={() => handleUnblockPlayer(row.playerId)} color="primary">
          <LockOpenIcon />
        </IconButton>
      )
    },
  ];

  const filterFields = [
    {
      type: 'text',
      label: 'Username',
      name: 'username'
    },
    {
      type: 'text',
      label: 'Player ID',
      name: 'playerId'
    },
    {
      type: 'date',
      label: 'Blocked Date From',
      name: 'dateFrom'
    },
    {
      type: 'date',
      label: 'Blocked Date To',
      name: 'dateTo'
    }
  ];

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4">Blocked Players</Typography>
          <Button onClick={() => setFiltersVisible(!filtersVisible)} variant="contained" color="primary">
            {filtersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {filtersVisible ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Box>

        <DynamicListComponent
          filters={formFilters}
          filterFields={filterFields}
          onFilterChange={handleFilterChange}
          onDateChange={handleDateChange}
          onFilterSubmit={handleFilterSubmit}
          onResetFilters={handleResetFilters}
          filtersVisible={filtersVisible}
          data={players}
          columns={columns}
          sortableFields={['playerId', 'userName', 'date']}
          pagination={{
            pageSize,
            currentPage,
            totalRecords,
            onPageChange: setCurrentPage,
            onPageSizeChange: handlePageSizeChange,
            onGoToPageChange: handleGoToPageChange,
            onGoToPage: handleGoToPage,
            onNextPage: handleNextPage,
            onPreviousPage: handlePreviousPage,
            goToPage,
            setGoToPage
          }}
        />
      </Container>
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      />
    </Layout>
  );
};

export default BlockedPlayers;
