import React from 'react';
import { Snackbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const SnackbarComponent = ({ open, message, onClose }) => {
  const isLandscape = useMediaQuery('(orientation: landscape)');

  return (
    <Snackbar
      open={open}
      message={message}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={isLandscape ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'center' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
          color: process.env.REACT_APP_PRIMARY_COLOR,
        },
      }}
    />
  );
};

export default SnackbarComponent;
