import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Button,
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Layout from '../shared/Layout';
import SnackbarComponent from '../shared/SnackbarComponent';
import api from '../../services/api';
import { useTheme } from '@mui/material/styles';

const GeneralLimits = () => {
  const theme = useTheme();
  const [playerLimits, setPlayerLimits] = useState(null);
  const [agentLimits, setAgentLimits] = useState(null);
  const [editing, setEditing] = useState({ players: false, agents: false });
  const [loading, setLoading] = useState({ players: false, agents: false });
  const [tempLimits, setTempLimits] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });

  useEffect(() => {
    const fetchLimits = async () => {
      try {
        const [playerResponse, agentResponse] = await Promise.all([
          api.get('/players/limits/default'),
          api.get('/agent/limits/default'),
        ]);
        setPlayerLimits(playerResponse.data);
        setAgentLimits(agentResponse.data);
      } catch (error) {
        console.error('Error fetching limits:', error);
        setSnackbar({ open: true, message: 'Failed to fetch limits', type: 'error' });
      }
    };

    fetchLimits();
  }, []);

  const capitalizeLabel = (label) => {
    return label
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleEdit = (type) => {
    setEditing((prev) => ({ ...prev, [type]: true }));
    setTempLimits((prev) => ({
      ...prev,
      [type]: type === 'players' ? { ...playerLimits } : { ...agentLimits },
    }));
  };

  const handleChange = (type, field, value) => {
    setTempLimits((prev) => ({
      ...prev,
      [type]: { ...prev[type], [field]: parseInt(value, 10) || 0 },
    }));
  };

  const handleSave = async (type) => {
    setLoading((prev) => ({ ...prev, [type]: true }));
    try {
      const endpoint = type === 'players' ? '/players/limits/default/update' : '/agent/limits/default/update';
      await api.post(endpoint, tempLimits[type]);

      if (type === 'players') {
        setPlayerLimits(tempLimits[type]);
      } else {
        setAgentLimits(tempLimits[type]);
      }
      setEditing((prev) => ({ ...prev, [type]: false }));
      setSnackbar({ open: true, message: 'Limits updated successfully', type: 'success' });
    } catch (error) {
      console.error(`Error updating ${type} limits:`, error);
      setSnackbar({ open: true, message: 'Failed to update limits', type: 'error' });
    } finally {
      setLoading((prev) => ({ ...prev, [type]: false }));
    }
  };

  const handleCancel = (type) => {
    setEditing((prev) => ({ ...prev, [type]: false }));
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderLimitsCard = (limits, type) => {
    const filteredLimits = Object.entries(limits || {}).filter(([key]) => key !== '_id' && key !== '__v');

    return (
      <Card sx={{ position: 'relative', height: '100%', borderRadius: theme.shape.borderRadius, boxShadow: theme.shadows[1] }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" color="text.primary">
              {type === 'players' ? 'Player Default Limits' : 'Agent Default Limits'}
            </Typography>
            {!editing[type] && (
              <IconButton onClick={() => handleEdit(type)} sx={{ color: theme.palette.primary.main }}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
          {filteredLimits.map(([field, value]) => (
            <Box key={field} display="flex" alignItems="center" mb={2}>
              <Typography sx={{ flex: 1, whiteSpace: 'nowrap', color: theme.palette.text.secondary }}>
                {capitalizeLabel(field)}:
              </Typography>
              <TextField
                value={editing[type] ? tempLimits[type][field] : value}
                disabled={!editing[type]}
                onChange={(e) => handleChange(type, field, e.target.value)}
                type="number"
                size="small"
                fullWidth
                inputProps={{ maxLength: 6 }}
              />
            </Box>
          ))}
          {editing[type] && (
            <Box display="flex" justifyContent="flex-end" mt={2}>
              {loading[type] ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => handleSave(type)}
                    sx={{ marginRight: 1 }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<CancelIcon />}
                    onClick={() => handleCancel(type)}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h4" color="text.primary">
            General Limits
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Player Limits */}
          <Grid item xs={12} sm={6}>
            {playerLimits && renderLimitsCard(playerLimits, 'players')}
          </Grid>

          {/* Agent Limits */}
          <Grid item xs={12} sm={6}>
            {agentLimits && renderLimitsCard(agentLimits, 'agents')}
          </Grid>
        </Grid>
        <SnackbarComponent
          open={snackbar.open}
          message={snackbar.message}
          onClose={handleCloseSnackbar}
        />
      </Container>
    </Layout>
  );
};

export default GeneralLimits;
