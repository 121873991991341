import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/account/Login';
import ActivePlayers from './components/players/ActivePlayers';
import BlockedPlayers from './components/players/BlockedPlayers';
import TransactionsList from './components/transactions/TransactionsList';
import BetList from './components/bets/BetList';
import PlayerInfo from './components/players/PlayerInfo';
import AgentsTree from './components/agents/AgentsTree';
import TestDisconnect from './components/account/TestDisconnect';
import { AuthContext } from './context/AuthContext';
import GeneralLimits from './components/risk/GeneralLimits';
import AgentLimitsTemplates from './components/risk/AgentLimitsTemplates';
import PlayerLimitsTemplates from './components/risk/PlayersLimitsTemplates';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, checkAuth } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const verifyAuth = async () => {
      await checkAuth();
      setLoading(false);
    };
    verifyAuth();
  }, [checkAuth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
    const { user } = React.useContext(AuthContext);
    return (
      <Route
        {...rest}
        render={(props) =>
          !user ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
  };
  

const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/playerinfo/:_id" component={PlayerInfo} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute path="/active-players" component={ActivePlayers} />
        <PrivateRoute path="/blocked-players" component={BlockedPlayers} />
        <PrivateRoute path="/transactions" component={TransactionsList} />
        <PrivateRoute path="/bets" component={BetList} />
        <PrivateRoute path="/agents-tree" component={AgentsTree} />
        <PrivateRoute path="/general-limits" component={GeneralLimits} />
        <PrivateRoute path="/agent-limits-templates" component={AgentLimitsTemplates} />
        <PrivateRoute path="/player-limits-templates" component={PlayerLimitsTemplates} />
        <PrivateRoute path="/testdisconnect" component={TestDisconnect} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
