import React, { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import { AuthContext } from '../../context/AuthContext';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Footer from './Footer';
import Header from './Header';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const drawerWidth = 240;

const Layout = ({ children }) => {
  const { user, checkAuth } = useContext(AuthContext);
  const [balance, setBalance] = useState(user ? user.balance : null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const { i18n, t } = useTranslation(); // Initialize translation

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const formatCurrency = (value) => {
    const currency = t('currency'); // Use translation key for currency
    return `${parseFloat(value).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'ar-TN', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })} ${currency}`;
  };

  const fetchBalance = async () => {
    try {
      const response = await api.get('/agent/balance', { withCredentials: true });
      const newBalance = response.data.balance;
      setBalance(formatCurrency(newBalance));
      checkAuth(); // Update the authentication state with the new balance
    } catch (error) {
      console.error(t('error.fetch_balance'), error); // Use translation for error message
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []); // Empty dependency array to ensure this runs only once

  if (!user) return <div>{t('loading')}</div>; // Translate "Loading..."

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Dynamically set direction
      }}
    >
      <Header
        balance={balance}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: i18n.language === 'ar' ? 0 : `${drawerWidth}px` }, // Adjust margin for RTL
          mr: { sm: i18n.language === 'ar' ? `${drawerWidth}px` : 0 }, // Adjust margin for RTL
          overflowY: 'auto', // Allow vertical scrolling
          mb: isLandscape ? 0 : '56px', // Space for the footer, hide in landscape mode
        }}
      >
        <Toolbar />
        {children}
      </Box>
      {!isLandscape && (
        <Footer
          fetchBalance={fetchBalance}
          formatCurrency={formatCurrency}
        />
      )}
    </Box>
  );
};

export default Layout;
