import React, { useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import SnackbarComponent from '../shared/SnackbarComponent';
import api from '../../services/api';
import theme from '../../theme';
import { useTranslation } from 'react-i18next'; // Import translation hook

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: theme.palette.background.paper,
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const ConfirmPlayerBlockModal = ({ open, handleClose, playerId, onActionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { t } = useTranslation(); // Initialize translation

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await api.post('/players/block', { playerId });

      if (response.data.result) {
        setSnackbarMessage(t('confirmBlockModal.success')); // Translated "Player blocked successfully"
        onActionComplete();
      } else {
        setSnackbarMessage(
          response.data.message || t('confirmBlockModal.failure') // Translated fallback
        );
      }
    } catch (error) {
      setSnackbarMessage(t('confirmBlockModal.error')); // Translated "An error occurred"
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      handleClose();
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h6" textAlign="center">
                {t('confirmBlockModal.message')} {/* Translated confirmation message */}
              </Typography>
              <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    width: 'calc(50% - 8px)',
                    borderColor: theme.palette.text.secondary,
                    color: theme.palette.text.secondary,
                  }}
                >
                  {t('confirmBlockModal.cancel')} {/* Translated "Cancel" */}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirm}
                  sx={{
                    width: 'calc(50% - 8px)',
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.error.contrastText,
                    '&:hover': { backgroundColor: theme.palette.error.dark },
                  }}
                >
                  {t('confirmBlockModal.confirm')} {/* Translated "Confirm" */}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default ConfirmPlayerBlockModal;
