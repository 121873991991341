import React, { useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import AddModal from '../modals/AddModal';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { useTranslation } from 'react-i18next'; // Import translation hook

const Footer = ({ fetchBalance, formatCurrency }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const { t } = useTranslation(); // Initialize translation

  const handleAddClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 16, // Adds space from the bottom
        left: 16,   // Adds space from the left
        right: 16,  // Adds space from the right
        borderRadius: '8px', // Rounded corners
        zIndex: 1300,
        display: { xs: 'block', sm: 'none' }, // Visible only on small screens
        boxShadow: 3,
      }}
      elevation={3}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px 0',
          backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
          borderRadius: '8px', // Matches Paper's border-radius
          '&:hover': {
            backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER, // Darker green on hover
          },
        }}
        onClick={handleAddClick}
      >
        <Typography
          sx={{
            fontSize: '1.25rem',
            color: process.env.REACT_APP_SECONDARY_COLOR,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AutoModeIcon
            sx={{
              ...(t('direction') === 'rtl' ? { ml: 1 } : { mr: 1 }), // Adjust icon spacing for RTL/LTR
            }}
          />
          {t('footer.new')} {/* Translate "New" */}
        </Typography>
      </Box>
      <AddModal
        open={openAddModal}
        handleClose={handleCloseAddModal}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
      />
    </Paper>
  );
};

export default Footer;
