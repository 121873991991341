import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR || '#027B5B', // Default to your brand color if env variable is missing
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR || '#F8C524',
      contrastText: '#202A35',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F8F9FA', // Slightly off-white for paper backgrounds
    },
    text: {
      primary: '#202A35', // Dark, sharp text
      secondary: '#6C727A', // Subtle, muted text
      disabled: '#A0A4A8', // Disabled text for clarity
    },
    success: {
      main: '#2E7D32', // Dark green for success
      light: '#81C784', // Light green for lighter success tones
      dark: '#1B5E20', // Darker green for contrast
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#D32F2F', // Deep red for errors
      light: '#E57373', // Light red for alerts
      dark: '#B71C1C', // Dark red for intense highlights
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FBC02D', // Bold yellow for warnings
      light: '#FFF176', // Light yellow for softer warnings
      dark: '#F57F17', // Amber tone for strong emphasis
      contrastText: '#202A35',
    },
    info: {
      main: '#0288D1', // Sky blue for information
      light: '#4FC3F7', // Lighter blue for subtle info
      dark: '#01579B', // Deep blue for contrast
      contrastText: '#FFFFFF',
    },
    divider: '#E0E0E0', // Subtle dividers for better visual separation
  },
  typography: {
    fontFamily: `'Arial', 'Helvetica', 'sans-serif'`, // Well-supported font stack
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
      color: '#202A35',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.3,
      color: '#202A35',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: 1.4,
      color: '#202A35',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      color: '#6C727A',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
      color: '#6C727A',
    },
    button: {
      textTransform: 'none', // Preserve casing for buttons
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 5, // Subtle rounded corners for UI elements
  },
  shadows: [
    'none', // Level 0
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', // Level 1
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // Level 2
    ...Array(23).fill('0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'), // Custom Levels 3–25
  ],
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '48px',
          padding: '12px 16px',
        },
      },
    },
  }
});

export default theme;
