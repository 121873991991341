const ROLES = {
    OWNER: 0,
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SHOP: 3,
};

const ROLE_NAMES = {
    [ROLES.OWNER]: 'Owner',
    [ROLES.SUPER_ADMIN]: 'Super Admin',
    [ROLES.ADMIN]: 'Admin',
    [ROLES.SHOP]: 'Shop',
};

const OLD_API_ROLES = {
    0: ROLES.OWNER,
    20: ROLES.SUPER_ADMIN,
    30: ROLES.SUPER_ADMIN,
    40: ROLES.SUPER_ADMIN,
    50: ROLES.ADMIN,
    60: ROLES.ADMIN,
    70: ROLES.SHOP,
};

// Utility function to map old API role to new API role
const mapOldApiRoleToNew = (oldApiRole) => OLD_API_ROLES[oldApiRole] ?? null;

module.exports = { ROLES, ROLE_NAMES, OLD_API_ROLES, mapOldApiRoleToNew };
