import React, { useState } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ChangeAgentPasswordModal from './ChangeAgentPasswordModal';
import ConfirmBlockUnblockModal from './ConfirmBlockUnblockModal';
import RiskSettingsModal from './RiskSettingsModal';
import { useRole } from '../../context/RoleContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const AgentActionsModal = ({ open, handleClose, agent }) => {
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [riskModalOpen, setRiskModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { isOwner } = useRole();
  const handlePasswordModalOpen = () => setPasswordModalOpen(true);
  const handlePasswordModalClose = () => setPasswordModalOpen(false);

  const handleBlock = () => {
    setActionType('Block');
    setConfirmModalOpen(true);
  };

  const handleUnblock = () => {
    setActionType('Unblock');
    setConfirmModalOpen(true);
  };
  const handleConfirmClose = () => setConfirmModalOpen(false);

  const handleActionComplete = () => {
    setConfirmModalOpen(false);
    console.log(`${actionType} action completed for agent.`);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="agent-actions-title"
        aria-describedby="agent-actions-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="agent-actions-title" variant="h6" component="h2">
              Agent Actions
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="agent-actions-description" variant="body1" mb={2}>
            Actions for agent: <strong>{agent?.userName || 'N/A'}</strong>
          </Typography>
          <Button
            variant="contained"
            startIcon={<TransferWithinAStationIcon />}
            sx={{
              mb: 1,
              backgroundColor: '#027B5B',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#025F45' },
            }}
            onClick={() => console.log('Money Transfer clicked')}
          >
            Money Transfer
          </Button>
          <Button
            variant="contained"
            startIcon={<LockIcon />}
            sx={{
              mb: 1,
              backgroundColor: '#027B5B',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#025F45' },
            }}
            onClick={handlePasswordModalOpen}
          >
            Change Password
          </Button>
          {agent?.statusId === "2" && (
            <Button
              variant="contained"
              startIcon={<BlockIcon />}
              sx={{
                mb: 1,
                backgroundColor: '#B71C1C',
                color: '#FFFFFF',
                '&:hover': { backgroundColor: '#8B0000' },
              }}
              onClick={handleBlock}
            >
              Block
            </Button>
          )}
          {agent?.statusId === "3" && (
            <Button
              variant="contained"
              startIcon={<CheckCircleIcon />}
              sx={{
                mb: 1,
                backgroundColor: '#1E88E5',
                color: '#FFFFFF',
                '&:hover': { backgroundColor: '#1565C0' },
              }}
              onClick={handleUnblock}
            >
              Unblock
            </Button>
          )}
          {isOwner && (<Button
            variant="contained"
            startIcon={<SettingsIcon />}
            sx={{
              mb: 1,
              backgroundColor: '#027B5B',
              color: '#FFFFFF',
              '&:hover': { backgroundColor: '#025F45' },
            }}
            onClick={() => setRiskModalOpen(true)}
          >
            Change Risk Settings
          </Button>
          )}
          <Button
            onClick={handleClose}
            variant="outlined"
            fullWidth
            sx={{
              mt: 2,
              borderColor: '#027B5B',
              color: '#027B5B',
              '&:hover': {
                borderColor: '#025F45',
                backgroundColor: '#F0F8F5',
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      <ChangeAgentPasswordModal
        open={passwordModalOpen}
        handleClose={handlePasswordModalClose}
        agentId={agent?.agentId}
        email={agent?.userName}
        setSnackbarOpen={(message) => console.log(message)}
      />

      <ConfirmBlockUnblockModal
        open={confirmModalOpen}
        handleClose={handleConfirmClose}
        agentId={agent?.agentId}
        actionType={actionType}
        onActionComplete={handleActionComplete}
      />

      <RiskSettingsModal
        open={riskModalOpen}
        handleClose={() => setRiskModalOpen(false)}
        agentId={agent?.agentId}
      />
    </>
  );
};

export default AgentActionsModal;
