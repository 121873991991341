import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  IconButton,
  Button,
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Layout from '../shared/Layout';
import SnackbarComponent from '../shared/SnackbarComponent';
import api from '../../services/api';
import { useTheme } from '@mui/material/styles';

const PlayersLimitsTemplates = () => {
  const theme = useTheme();
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editing, setEditing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tempTemplate, setTempTemplate] = useState({});
  const [defaultLimits, setDefaultLimits] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });

  const nameInputRef = useRef(null);

  useEffect(() => {
    fetchTemplates();
    fetchDefaultLimits();
  }, []);

  const fetchTemplates = async () => {
    try {
      const payload = searchQuery.trim() ? { name: searchQuery } : {};
      const response = await api.post('/players/limits/template/list', payload);
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setSnackbar({ open: true, message: 'Failed to fetch templates', type: 'error' });
    }
  };

  const fetchDefaultLimits = async () => {
    try {
      const response = await api.get('/players/limits/default');
      setDefaultLimits(response.data);
    } catch (error) {
      console.error('Error fetching default limits:', error);
      setSnackbar({ open: true, message: 'Failed to fetch default limits', type: 'error' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchTemplates();
  };

  const handleCreate = () => {
    const newTemplate = {
      templateName: 'New Template',
      maxDailyDeposit: defaultLimits.maxDailyDeposit || 0,
      maxDailyWithdraw: defaultLimits.maxDailyWithdraw || 0,
      maxBalanceOnDeposit: defaultLimits.maxBalanceOnDeposit || 0,
    };
    setTemplates((prev) => [newTemplate, ...prev]);
    setEditing(newTemplate);
    setTempTemplate(newTemplate);
    setTimeout(() => {
      nameInputRef.current?.select();
    }, 0);
  };

  const handleEdit = (template) => {
    setEditing(template);
    setTempTemplate(template);
    setTimeout(() => {
      nameInputRef.current?.select();
    }, 0);
  };

  const handleDelete = async (templateId) => {
    try {
      await api.post('/players/limits/template/delete', { _id: templateId });
      setSnackbar({ open: true, message: 'Template deleted successfully', type: 'success' });
      fetchTemplates();
    } catch (error) {
      console.error('Error deleting template:', error);
      setSnackbar({ open: true, message: 'Failed to delete template', type: 'error' });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (!tempTemplate._id) {
        // Insert new template
        const response = await api.post('/players/limits/template/insert', tempTemplate);
        setSnackbar({ open: true, message: 'Template created successfully', type: 'success' });
        setTemplates((prev) => {
          const updatedTemplates = [...prev];
          const index = updatedTemplates.findIndex((tpl) => tpl.templateName === tempTemplate.templateName);
          updatedTemplates[index] = response.data; // Replace with the created template
          return updatedTemplates;
        });
      } else {
        // Update existing template
        await api.post('/players/limits/template/update', tempTemplate);
        setSnackbar({ open: true, message: 'Template updated successfully', type: 'success' });
      }
      fetchTemplates();
      setEditing(null);
    } catch (error) {
      console.error('Error saving template:', error);
      setSnackbar({ open: true, message: 'Failed to save template', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (!tempTemplate._id) {
      // Remove unsaved template
      setTemplates((prev) => prev.filter((template) => template.templateName !== tempTemplate.templateName));
    }
    setEditing(null);
  };

  const handleTemplateChange = (field, value) => {
    setTempTemplate((prev) => ({ ...prev, [field]: field === 'templateName' ? value : parseInt(value, 10) || 0 }));
  };

  const capitalizeLabel = (label) =>
    label
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h4" color="text.primary">
            Player Limits Templates
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreate}
          >
            Create New Template
          </Button>
        </Box>

        {/* Search */}
        <Box component="form" onSubmit={handleSearchSubmit} mb={4} display="flex">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by template name"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
        </Box>

        {/* Templates */}
        <Grid container spacing={4}>
          {templates.map((template) => (
            <Grid item xs={12} sm={6} key={template._id || template.templateName}>
              <Card
                sx={{
                  position: 'relative',
                  height: '100%',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[1],
                }}
              >
                <CardContent>
                  {editing && editing.templateName === template.templateName ? (
                    <>
                      <TextField
                        fullWidth
                        label="Template Name"
                        value={tempTemplate.templateName}
                        onChange={(e) => handleTemplateChange('templateName', e.target.value)}
                        inputRef={nameInputRef}
                        sx={{ mb: 2 }}
                      />
                      {Object.keys(tempTemplate)
                        .filter((key) => key.startsWith('max'))
                        .map((field) => (
                          <TextField
                            key={field}
                            fullWidth
                            label={capitalizeLabel(field)}
                            type="number"
                            value={tempTemplate[field]}
                            onChange={(e) => handleTemplateChange(field, e.target.value)}
                            sx={{ mb: 2 }}
                          />
                        ))}
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        {loading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSave}
                              sx={{ marginRight: 1 }}
                            >
                              Save
                            </Button>
                            <Button variant="contained" color="error" onClick={handleCancel}>
                              Cancel
                            </Button>
                          </>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="h6" color="text.primary">
                        {template.templateName}
                      </Typography>
                      {Object.keys(template)
                        .filter((key) => key.startsWith('max'))
                        .map((field) => (
                          <Typography key={field} color="text.secondary">
                            {capitalizeLabel(field)}: {template[field]}
                          </Typography>
                        ))}
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <IconButton onClick={() => handleEdit(template)} sx={{ color: theme.palette.primary.main }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(template._id)} sx={{ color: theme.palette.error.main }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Snackbar */}
        <SnackbarComponent
          open={snackbar.open}
          message={snackbar.message}
          onClose={() => setSnackbar({ open: false, message: '', type: '' })}
        />
      </Container>
    </Layout>
  );
};

export default PlayersLimitsTemplates;
