import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { useTranslation } from 'react-i18next'; // Import translation hook

const TotalPlayersCount = ({ totalPlayers, sx }) => {
  const history = useHistory(); // Initialize useHistory
  const { t, i18n } = useTranslation(); // Initialize translation

  const handleCardClick = () => {
    history.push('/players'); // Navigate to the players list
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        ...sx,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <PeopleIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box
            sx={{
              ...(i18n.language === 'ar' ? { mr: 2 } : { ml: 2 }), // Adjust spacing for RTL/LTR
            }}
          >
            <Typography variant="h6">{t('total_players_count.title')}</Typography> {/* Translate "Total Players Count" */}
            <Typography variant="h4">{totalPlayers}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalPlayersCount;
