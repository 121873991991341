import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  Box,
  Collapse,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { AuthContext } from '../../context/AuthContext';
import { useRole } from '../../context/RoleContext';
import NotificationModal from '../account/NotificationModal';
import AddModal from '../modals/AddModal';
import NewPlayerModal from '../players/NewPlayerModal';
import AccountMenu from '../account/AccountMenu';
import api from '../../services/api';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { useHoory } from '@hoory/react';

const logo = `${process.env.REACT_APP_LOGO}`;
const drawerWidth = 240;
const Header = ({ balance, mobileOpen, handleDrawerToggle, fetchBalance, formatCurrency }) => {
  const { t } = useTranslation(); // Initialize the translation function
  const { user, logout } = useContext(AuthContext);
  const { isAuthorizedForAgentsTree, isOwner } = useRole();
  const { i18n } = useTranslation(); // Access i18n instance
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openNewPlayerModal, setOpenNewPlayerModal] = useState(false);
  const [newPlayer, setNewPlayer] = useState(null);
  const [riskManagementMenuOpen, setRiskManagementMenuOpen] = useState(false);
  const [playersMenuOpen, setPlayersMenuOpen] = useState(false);
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const API = useHoory(process.env.REACT_APP_HOORY_WIDGET_ID, {
    env: 'PROD',
    launcherTitle: 'Need help? Chat with us!',
    position: 'right',
    darkMode: false,
    locale: i18n.language || 'en',
    manualLoad: true,
    onLoad : (e) => {
      console.log(e)
    }
  });
  const toggleHoory = () => {
    API.runScript();

    if (API && API.hasLoaded) {
      API.setUser(user.currentUser.email, {
        email: user.currentUser.email,
        name: "",
        avatar_url: "",
        phone_number: "",
      });
      API.toggle('open'); // Toggle widget state
    } else {
      console.error('Hoory API is not ready yet.');
    }
  };
  useEffect(() => {
    // Add custom CSS to hide the bubble holder
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      .woot--bubble-holder {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    // Clean up the style when the component unmounts
    return () => {
      if (style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);
  useEffect(() => {
    toggleHoory();
  }, []);
  const handleNotificationClick = () => {
    setOpenNotificationModal(true);
  };

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  const fetchNotificationsCount = async () => {
    try {
      const response = await api.post('/notifications', {
        start: 0,
        limit: 6,
        filter: {},
      });
      setUnreadNotifications(response.data.result.totalUnreadUserNotificationsCount);
    } catch (error) {
      console.error('Error fetching notifications count:', error);
    }
  };

  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  const handleAddClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleCloseNewPlayerModal = () => {
    setOpenNewPlayerModal(false);
    setNewPlayer(null);
  };

  const toggleRiskManagementMenu = () => {
    setRiskManagementMenuOpen((prev) => !prev);
  };

  const togglePlayersMenu = () => {
    setPlayersMenuOpen((prev) => !prev);
  };

  const desktopDrawer = (
    <Box
      sx={{
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Adjust text direction
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        {isLandscape && (
          <ListItem
            button
            onClick={handleAddClick}
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              borderRadius: '8px',
              margin: '8px 16px',
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '1rem',
                color: process.env.REACT_APP_SECONDARY_COLOR,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AutoModeIcon
                sx={{
                  ...(i18n.language === 'ar' ? { ml: 1 } : { mr: 1 }), // Adjust icon spacing for RTL/LTR
                }}
              />
              {t('header.new')} {/* Translate "New" */}
            </Typography>
          </ListItem>
        )}
        <ListItem button component={Link} to="/dashboard">
          <ListItemText
            primary={t('header.dashboard')} // Translate "Dashboard"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left', // Adjust text alignment
            }}
          />
        </ListItem>
        {isAuthorizedForAgentsTree && (
          <ListItem button component={Link} to="/agents-tree">
            <ListItemText
              primary={t('header.agentsTree')} // Translate "Agents Tree"
              sx={{
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
              }}
            />
          </ListItem>
        )}
        <ListItem button onClick={togglePlayersMenu}>
          <ListItemText
            primary={t('header.players')} // Translate "Players"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
          {playersMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={playersMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/active-players" sx={{ pl: 4 }}>
              <ListItemText
                primary={t('header.activePlayers')} // Translate "Active Players"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
            </ListItem>
            <ListItem button component={Link} to="/blocked-players" sx={{ pl: 4 }}>
              <ListItemText
                primary={t('header.blockedPlayers')} // Translate "Blocked Players"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={Link} to="/transactions">
          <ListItemText
            primary={t('header.transactions')} // Translate "Transactions List"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
        </ListItem>
        <ListItem button component={Link} to="/bets">
          <ListItemText
            primary={t('header.bets')} // Translate "Bet List"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
        </ListItem>

        {isOwner && (
          <>
            <ListItem button onClick={toggleRiskManagementMenu}>
              <ListItemText
                primary={t('header.riskManagement')} // Translate "Risk Management"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
              {riskManagementMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={riskManagementMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/general-limits" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.generalLimits')} // Translate "General Limits"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
                <ListItem button component={Link} to="/agent-limits-templates" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.agentLimitsTemplates')} // Translate "Agent Limits Templates"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
                <ListItem button component={Link} to="/player-limits-templates" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.playerLimitsTemplates')} // Translate "Player Limits Templates"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        <ListItem
          button
          onClick={toggleHoory} // Trigger the Hoory toggle function
        >
          <ListItemText
            primary={t('header.openChat')} // Translate "Open Chat"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left', // Adjust alignment for RTL/LTR
            }}
          />
        </ListItem>

      </List>
    </Box>
  );



  const mobileDrawer = (
    <Box
      sx={{
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr', // Adjust text direction
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemText
            primary={t('header.dashboard')} // Translate "Dashboard"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left', // Align text for RTL/LTR
            }}
          />
        </ListItem>
        {isAuthorizedForAgentsTree && (
          <ListItem button component={Link} to="/agents-tree">
            <ListItemText
              primary={t('header.agentsTree')} // Translate "Agents Tree"
              sx={{
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
              }}
            />
          </ListItem>
        )}
        <ListItem button onClick={togglePlayersMenu}>
          <ListItemText
            primary={t('header.players')} // Translate "Players"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
          {playersMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={playersMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/active-players" sx={{ pl: 4 }}>
              <ListItemText
                primary={t('header.activePlayers')} // Translate "Active Players"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
            </ListItem>
            <ListItem button component={Link} to="/blocked-players" sx={{ pl: 4 }}>
              <ListItemText
                primary={t('header.blockedPlayers')} // Translate "Blocked Players"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={Link} to="/transactions">
          <ListItemText
            primary={t('header.transactions')} // Translate "Transactions List"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
        </ListItem>
        <ListItem button component={Link} to="/bets">
          <ListItemText
            primary={t('header.bets')} // Translate "Bet List"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left',
            }}
          />
        </ListItem>

        {isOwner && (
          <>
            <ListItem button onClick={toggleRiskManagementMenu}>
              <ListItemText
                primary={t('header.riskManagement')} // Translate "Risk Management"
                sx={{
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',
                }}
              />
              {riskManagementMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={riskManagementMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/general-limits" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.generalLimits')} // Translate "General Limits"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
                <ListItem button component={Link} to="/agent-limits-templates" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.agentLimitsTemplates')} // Translate "Agent Limits Templates"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
                <ListItem button component={Link} to="/player-limits-templates" sx={{ pl: 4 }}>
                  <ListItemText
                    primary={t('header.playerLimitsTemplates')} // Translate "Player Limits Templates"
                    sx={{
                      textAlign: i18n.language === 'ar' ? 'right' : 'left',
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        <ListItem
          button
          onClick={toggleHoory} // Trigger the Hoory toggle function
        >
          <ListItemText
            primary={t('header.openChat')} // Translate "Open Chat"
            sx={{
              textAlign: i18n.language === 'ar' ? 'right' : 'left', // Adjust alignment for RTL/LTR
            }}
          />
        </ListItem>
      </List>
    </Box>
  );



  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={t('header.openDrawer')} // Translate "Open Drawer"
            edge={i18n.language === 'ar' ? 'end' : 'start'} // Adjust button edge for RTL/LTR
            onClick={handleDrawerToggle}
            sx={{
              ...(i18n.language === 'ar' ? { ml: 2 } : { mr: 2 }), // Adjust spacing for RTL/LTR
              display: { sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/dashboard">
            <img src={logo} alt={t('header.logoAlt')} style={{ height: 32 }} /> {/* Translate alt text */}
          </Link>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
          {isLandscape && (
            <Box display="flex" alignItems="center" gap={2}>
              <AccountBalanceWalletIcon />
              <Typography variant="h6" noWrap>
                {balance}
              </Typography>
              <IconButton
                aria-label={t('header.notifications')} // Translate "Notifications"
                color="inherit"
                onClick={handleNotificationClick}
              >
                {unreadNotifications > 0 ? (
                  <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
              <AccountMenu user={user} logout={logout} />
            </Box>
          )}
          {!isLandscape && (
            <>
              <AccountBalanceWalletIcon />
              <Typography variant="h6" noWrap>
                {balance}
              </Typography>
              <IconButton
                aria-label={t('header.notifications')} // Translate "Notifications"
                color="inherit"
                onClick={handleNotificationClick}
              >
                {unreadNotifications > 0 ? (
                  <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
              <AccountMenu user={user} logout={logout} />
            </>
          )}

          <NotificationModal
            open={openNotificationModal}
            handleClose={handleCloseNotificationModal}
            setUnreadNotificationsCount={setUnreadNotifications}
          />
          <AddModal
            open={openAddModal}
            handleClose={handleCloseAddModal}
            fetchBalance={fetchBalance}
            formatCurrency={formatCurrency}
          />
          <NewPlayerModal
            open={openNewPlayerModal}
            handleClose={handleCloseNewPlayerModal}
            playerData={newPlayer}
          />
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            ...(i18n.language === 'ar'
              ? { right: 0, left: 'auto' } // Anchor to the right for RTL
              : { left: 0, right: 'auto' }), // Anchor to the left for LTR
          },
        }}
      >
        {mobileDrawer}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            ...(i18n.language === 'ar'
              ? { right: 0, left: 'auto' } // Anchor to the right for RTL
              : { left: 0, right: 'auto' }), // Anchor to the left for LTR
          },
        }}
        open
      >
        {desktopDrawer}
      </Drawer>
    </>
  );
};

export default Header;
