import React, { useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  keyframes,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api';
import NewPlayerModal from './NewPlayerModal';
import { useTranslation } from 'react-i18next';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const style = {
  position: 'absolute',
  bottom: 60,
  left: '5%',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  animation: `${slideUp} 0.5s`,
};

const NewPlayerFormModal = ({ open, handleClose, handleSetNewPlayer }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ username: '', password: '' });
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const [createdPlayer, setCreatedPlayer] = useState(null);
  const { i18n, t } = useTranslation(); // Initialize translation

  const validateUsername = (username, t) => {
    if (username.length < 3) {
      return t('validation.usernameShort');
    }
    return '';
  };

  const validatePassword = (password, username, t) => {
    if (password.length < 6) {
      return t('validation.passwordShort');
    }
    if (password.includes(username)) {
      return t('validation.passwordContainsUsername');
    }
    let sequenceCount = 1;
    for (let i = 0; i < password.length - 1; i++) {
      if (password.charCodeAt(i) + 1 === password.charCodeAt(i + 1)) {
        sequenceCount++;
        if (sequenceCount > 4) {
          return t('validation.passwordSequence');
        }
      } else {
        sequenceCount = 1;
      }
    }
    const charCounts = {};
    for (const char of password) {
      charCounts[char] = (charCounts[char] || 0) + 1;
    }
    for (const char in charCounts) {
      if (charCounts[char] >= 5) {
        return t('validation.passwordRepeat');
      }
    }
    return '';
  };

  const generatePassword = () => {
    const digit1 = Math.floor(Math.random() * 9) + 1;
    let digit2;
    do {
      digit2 = Math.floor(Math.random() * 9) + 1;
    } while (digit2 === digit1);
    const zeroCount = Math.random() < 0.5 ? 3 : 4;
    const zeroString = '0'.repeat(zeroCount);
    const remainingLength = 8 - zeroCount;
    let otherDigits = '';
    for (let i = 0; i < remainingLength; i++) {
      otherDigits += Math.random() < 0.5 ? digit1 : digit2;
    }
    const position = Math.floor(Math.random() * (otherDigits.length + 1));
    const newPassword = otherDigits.slice(0, position) + zeroString + otherDigits.slice(position);
    setPassword(newPassword);
    setErrors((prev) => ({ ...prev, password: validatePassword(newPassword, username, t) }));
  };

  const handleCreatePlayer = async (e) => {
    e.preventDefault();
    const usernameError = validateUsername(username, t);
    const passwordError = validatePassword(password, username, t);
    if (usernameError || passwordError) {
      setErrors({ username: usernameError, password: passwordError });
      return;
    }
    setLoading(true);
    setApiError('');
    try {
      const response = await api.post('/players/create/normal', {
        credentials: {
          username,
          password,
        },
      });
      const data = response.data;
      if (data.status === 'success') {
        handleSetNewPlayer(data.player);
        setCreatedPlayer(data.player);
        setUsername('');
        setPassword('');
        handleCloseModal();
      } else {
        if (data.notification && data.notification[0] && data.notification[0].code === 1) {
          setErrors((prev) => ({ ...prev, username: t('validation.usernameExists') }));
        } else {
          setApiError(data.notification && data.notification[0] ? data.notification[0].text : t('validation.createPlayerError'));
        }
      }
    } catch (error) {
      console.error(t('validation.createPlayerApiError'), error);
      setApiError(t('validation.createPlayerRetry'));
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setUsername('');
    setPassword('');
    setErrors({ username: '', password: '' });
    setApiError('');
    setLoading(false);
    setCreatedPlayer(null);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open && !createdPlayer}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('createPlayer.title')}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {apiError && (
                <Typography color="error" variant="body1" mb={2}>
                  {apiError}
                </Typography>
              )}
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                  color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                  '&:hover': {
                    backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                  },
                  mt: 2,
                }}
                onClick={async () => {
                  setLoading(true);
                  setApiError('');
                  try {
                    const response = await api.post('/players/create/fast', {});
                    const data = response.data;
                    if (data.status === 'success') {
                      handleSetNewPlayer(data.player);
                      setCreatedPlayer(data.player);
                      handleCloseModal();
                    } else {
                      setApiError(data.notification || t('createPlayer.fastCreateError'));
                    }
                  } catch (error) {
                    console.error(t('createPlayer.fastCreateApiError'), error);
                    setApiError(t('createPlayer.fastCreateApiErrorRetry'));
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {t('createPlayer.fastCreate')}
              </Button>

              <form onSubmit={handleCreatePlayer}>
                  <TextField
                    label={t('createPlayer.username')}
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                      setErrors((prev) => ({ ...prev, username: validateUsername(e.target.value, t) }));
                    }}
                    fullWidth
                    margin="normal"
                    error={!!errors.username}
                    helperText={errors.username}
                    autoComplete="off"
                    InputProps={{
                      inputProps: {
                        dir: i18n.language === 'ar' ? 'rtl' : 'ltr', // Set text direction
                        style: {
                          textAlign: i18n.language === 'ar' ? 'right' : 'left', // Align text to the right for RTL
                        },
                      },
                    }}
                  />

                  <TextField
                    label={t('createPlayer.password')}
                    type="text"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors((prev) => ({ ...prev, password: validatePassword(e.target.value, username, t) }));
                    }}
                    fullWidth
                    margin="normal"
                    error={!!errors.password}
                    helperText={errors.password}
                    autoComplete="new-password"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleCreatePlayer(e);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        dir: i18n.language === 'ar' ? 'rtl' : 'ltr', // Set text direction
                        style: {
                          textAlign: i18n.language === 'ar' ? 'right' : 'left', // Align text to the right for RTL
                        },
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={generatePassword} size="small" color="primary">
                            {t('createPlayer.generatePassword')}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />


                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                      color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                      },
                    }}
                  >
                    {t('createPlayer.create')}
                  </Button>
                </Box>
              </form>
              <Box mt={2}>
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                    color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    '&:hover': {
                      backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                    },
                  }}
                >
                  {t('createPlayer.close')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      {createdPlayer && (
        <NewPlayerModal open={true} handleClose={handleCloseModal} playerData={createdPlayer} />
      )}
    </>
  );
};

export default NewPlayerFormModal;
