import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  TableContainer,
  Paper,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Layout from '../shared/Layout';
import api from '../../services/api';
import RoleBadge from '../shared/RoleBadge';
import AgentActionsModal from './AgentActionsModal'; // Import the modal component
import { AuthContext } from '../../context/AuthContext'; // Import AuthContext

const AgentsTree = () => {
  const { user } = useContext(AuthContext); // Get the current user from AuthContext
  const currentAgentId = user?.currentUser.affiliateId; // Extract the agent ID

  const [agents, setAgents] = useState([]);
  const [expandedAgents, setExpandedAgents] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingNodes, setLoadingNodes] = useState({});
  const [selectedAgent, setSelectedAgent] = useState(null); // State for selected agent
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility

  // Fetch root agents
  const fetchRootAgents = async (username = '') => {
    setLoading(true);
    try {
      const payload = {
        start: 0,
        limit: 10,
        filter: {
          currency: {
            action: '=',
            value: 'multi',
            valueLabel: 'multi',
          },
          ...(username && {
            agentUsername: {
              action: '-',
              value: username,
            },
          }),
        },
      };

      const response = await api.post('/agent/getTree', payload);
      const { root, children } = response.data;

      const rootAgent = root ? [{ ...root, children: [] }] : [];
      setAgents([...rootAgent, ...children.map((child) => ({ ...child, children: [] }))]);
      
    } catch (error) {
      console.error('Error fetching root agents:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch child agents
  const fetchChildAgents = async (parentAffiliateId) => {
    try {
      const payload = {
        start: 0,
        limit: 10,
        filter: {
          parentAffiliateId: {
            action: '=',
            value: parentAffiliateId,
          },
        },
        isPlus: true,
      };

      const response = await api.post('/agent/getTree', payload);
      return response.data.children || [];
    } catch (error) {
      console.error('Error fetching child agents:', error);
      return [];
    }
  };

  const updateAgentChildren = (agents, parentId, children) => {
    return agents.map((agent) => {
      if (agent.agentId === parentId) {
        return { ...agent, children }; // Update the parent with its children
      }
      if (agent.children?.length > 0) {
        // Recursively update nested children
        return { ...agent, children: updateAgentChildren(agent.children, parentId, children) };
      }
      return agent; // Return unchanged agent
    });
  };

  const toggleAgentExpand = async (agentId) => {
    setExpandedAgents((prev) => ({
      ...prev,
      [agentId]: !prev[agentId],
    }));

    if (!expandedAgents[agentId]) {
      setLoadingNodes((prev) => ({ ...prev, [agentId]: true }));
      const children = await fetchChildAgents(agentId);
      setAgents((prevAgents) => updateAgentChildren(prevAgents, agentId, children));
      setLoadingNodes((prev) => ({ ...prev, [agentId]: false }));
    }
  };

  const handleViewClick = (agent) => {
    setSelectedAgent(agent);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedAgent(null);
  };

  const levelColors = ['#027B5B', '#F8C524', '#6C727A', '#3E4751', '#202A35']; // Colors for levels

  const renderAgents = (agentList, level = 0) => (
    <>
      {agentList.map((agent) => (
        <React.Fragment key={agent.agentId}>
          <TableRow
            onClick={agent.hasChildren ? () => toggleAgentExpand(agent.agentId) : undefined}
            style={{
              cursor: agent.hasChildren ? 'pointer' : 'default',
              backgroundColor: agent.statusId === "3" ? '#fdecea' : '#fff', // Soft red for blocked agents, white otherwise
            }}
          >
            <TableCell
              style={{
                position: 'relative',
                position: 'sticky',
                left: 0,
                zIndex: 2,
                backgroundColor: agent.statusId === "3" ? '#fdecea' : '#fff', // Match row background color
                width: '30px',
                minWidth: '30px',
                padding: '8px',
              }}
            >
              {agent.hasChildren && (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleAgentExpand(agent.agentId);
                  }}
                  style={{
                    position: 'absolute',
                    left: '-8px',
                    top: '4px',
                  }}
                >
                  {expandedAgents[agent.agentId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: `${4 * level + 14}px`,
                  width: '1px',
                  borderLeft: `1px dashed ${levelColors[level % levelColors.length]}`,
                  zIndex: -1,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: `${4 * level + 18}px`,
                  height: '1px',
                  width: '20px',
                  borderTop: `1px dashed ${levelColors[level % levelColors.length]}`,
                  zIndex: -1,
                }}
              />
            </TableCell>

            {/* Role Cell */}
            <TableCell
              style={{
                position: 'sticky',
                left: '30px',
                zIndex: 2,
                backgroundColor: agent.statusId === "3" ? '#fdecea' : '#fff', // Match row background color
                width: '50px',
                minWidth: '50px',
                padding: '8px',
              }}
            >
              <RoleBadge role={Number(agent.agentRole)} />
            </TableCell>

            {/* Username Cell */}
            <TableCell
              style={{
                position: 'sticky',
                left: '80px',
                zIndex: 2,
                backgroundColor: agent.statusId === "3" ? '#fdecea' : '#fff', // Match row background color
                width: '50px',
                minWidth: '50px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '8px',
              }}
              title={agent.userName}
            >
              {agent.userName}
            </TableCell>

            <TableCell
              style={{
                width: '96px',
                minWidth: '96px',
                padding: '8px',
              }}
            >
              {agent.availability || 0} TND
            </TableCell>

            <TableCell
              style={{
                width: '70px',
                minWidth: '70px',
                padding: '8px',
              }}
            >
              {agent.playersCount || 0}
            </TableCell>

            <TableCell
              style={{
                width: '96px',
                minWidth: '96px',
                padding: '8px',
              }}
            >
              {Number(agent.agentId) !== currentAgentId && ( // Only render the button for non-root agents
                <Button
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewClick(agent);
                  }}
                >
                  View
                </Button>
              )}
            </TableCell>
          </TableRow>

          {expandedAgents[agent.agentId] &&
            agent.children &&
            renderAgents(agent.children, level + 1)}
        </React.Fragment>
      ))}
    </>
  );

  const handleSearch = () => {
    fetchRootAgents(searchQuery);
  };

  useEffect(() => {
    fetchRootAgents();
  }, []);

  return (
    <Layout>
      <Container>
        <Typography variant="h4" mb={2}>
          Agents Tree
        </Typography>

        {/* Search Input */}
        <Box mb={2} display="flex" alignItems="center" gap={2}>
          <TextField
            label="Search by Username"
            variant="outlined"
            size="small"
            autoComplete="off"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Box>

        {/* Table with Horizontal Scroll */}
        <Paper sx={{ width: '100%', height: 'calc(100vh - 200px)', overflow: 'hidden' }}>
          <TableContainer
            sx={{
              height: '100%',
              overflowX: 'auto',
              '@media (orientation: portrait)': {
                width: '100%',
              },
              '@media (orientation: landscape)': {
                width: '100%',
              },
            }}
          >
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 3,
                      background: '#fff',
                      width: '30px',
                      minWidth: '30px',
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: '30px',
                      zIndex: 3,
                      background: '#fff',
                      width: '50px',
                      minWidth: '50px',
                    }}
                  >Role</TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: '80px',
                      zIndex: 3,
                      background: '#fff',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    sx={{
                      '@media (orientation: portrait)': {
                        width: '100px', // Width for portrait mode
                        minWidth: '100px',
                      },
                    }}
                  >Username</TableCell>
                  <TableCell
                    style={{
                      width: '96px', // Fixed width
                      minWidth: '96px',
                    }}
                  >Balance</TableCell>
                  <TableCell
                    style={{
                      width: '70px', // Fixed width
                      minWidth: '70px',
                    }}
                  >Direct Players</TableCell>
                  <TableCell
                    style={{
                      width: '96px', // Fixed width
                      minWidth: '96px',
                    }}
                  >Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  renderAgents(agents)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>

      {/* Integrate AgentActionsModal */}
      <AgentActionsModal
        open={modalOpen}
        handleClose={handleCloseModal}
        agent={selectedAgent}
      />
    </Layout>
  );
};

export default AgentsTree;
