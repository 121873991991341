import React from 'react';
import { AuthProvider } from './context/AuthContext';
import { RoleProvider } from './context/RoleContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import AppRoutes from './AppRoutes'; // Import the separated routes
import './i18n'; // Import i18n configuration
import { useTranslation } from 'react-i18next';
const App = () => {
  const { i18n } = useTranslation();

  // Set text direction based on language
  React.useEffect(() => {
    document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);
  return (
    <AuthProvider>
      <RoleProvider>
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </RoleProvider>
    </AuthProvider>
  );
};

export default App;
