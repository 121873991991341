import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Snackbar,
  useMediaQuery,
  Button,
  Link
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import WebIcon from '@mui/icons-material/Web';
import gratefulImage from '../../assets/gratefulrabbi2t.png';
import kartooshbetlogo from '../../assets/kartooshbet-logo.png';
const PlayerInfo = () => {
  const { _id } = useParams();
  const [playerInfo, setPlayerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  useEffect(() => {
    const fetchPlayerInfo = async () => {
      try {
        const response = await api.get(`/players/getplayerinfo/${_id}`);
        setPlayerInfo(response.data);
      } catch (error) {
        setError('Failed to fetch player information');
      } finally {
        setLoading(false);
      }
    };
    fetchPlayerInfo();
  }, [_id]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(`Copied: ${text}`);
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#202A34', color: 'white', minHeight: '100vh', overflowY: 'auto' }}>
      <Box sx={{ backgroundColor: '#0B7B5C', padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#FFD700', color: '#202A34', fontWeight: 'bold', padding: '5px 10px' }}
          href="https://www.kartoosh.bet/ar/promotions/all"
          target="_blank"
        >
          عروضنا الترويجية
        </Button>
        <Link href="https://www.kartoosh.bet" target="_blank">
          <img src={kartooshbetlogo} alt="Kartoosh Logo" style={{ height: '20px' }} />
        </Link>
      </Box>
      <Box textAlign="center" sx={{ paddingY: 4 }}>
        <img src={gratefulImage} alt="Grateful Rabbi" style={{ height: '256px', marginBottom: '20px' }} />
        <Typography variant="h1" sx={{ fontFamily: 'Tahoma', fontSize: '50px', marginTop: '10px', fontWeight: 'bold' }}>
          مرحبا بيك في منصة كرطوش
        </Typography>
        <Typography variant="h2" sx={{ fontFamily: 'Tahoma', fontSize: '20px', marginTop: '10px' }}>
          نشكروك على ثقتك فينا <br />ونتمنولك حظ موفق.
        </Typography>
        <Box sx={{ padding: 3 }}>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
            الرابط متع موقع الرهان الخاص بنا موجود هنا فقط انقر عليه وإضغط على "Se Connecter" و انقل معلومات الدخول هذه.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 1 }}>
          هنا تلقى إسم المستخدم وكلمة السر متاعك ، أول ما تحل الحساب <strong>بدل كلمة السر</strong>
          </Typography>

          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', marginBottom: 2, backgroundColor: '#0B7B5C', color:'white', padding: '10px', borderRadius: '4px' }} onClick={() => handleCopyToClipboard(playerInfo.username)}>
            <Typography variant="body1"  sx={{color:'white'}}><strong>Username:</strong> {playerInfo.username}</Typography>
            <IconButton size="small" sx={{ marginLeft: 1 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', marginBottom: 2, backgroundColor: '#0B7B5C', color:'white', padding: '10px', borderRadius: '4px' }} onClick={() => handleCopyToClipboard(playerInfo.password)}>
            <Typography variant="body1" sx={{color:'white'}}><strong>Password:</strong> {playerInfo.password}</Typography>
            <IconButton size="small" sx={{ marginLeft: 1 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', marginBottom: 2, backgroundColor: '#0B7B5C', color:'white', padding: '10px', borderRadius: '4px' }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              <strong  sx={{color:'white'}} >Website Link:</strong> 
              <Link href="https://www.kartoosh.bet" target="_blank" sx={{ color: 'white', textDecoration: 'none', fontWeight: 'bold', marginLeft: '5px' }}>
                https://www.kartoosh.bet
              </Link>
            </Typography>
            <IconButton size="small" sx={{ marginLeft: 1 }}>
              <WebIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
          إنضم إلى المجموعة الرسمية على منصة فيسبوك واربح العديد من الجوائز القيمة!
        </Typography>
        <Box display="flex" alignItems="center" sx={{ cursor: 'pointer', marginTop: 4, marginBottom: 2, backgroundColor: '#FFD700', padding: '10px', borderRadius: '4px', color: 'black', fontWeight: 'bold' }}>
          <Button
            fullWidth
            href="https://www.facebook.com/groups/1474336993178510"
            target="_blank"
            sx={{ textTransform: 'none', color: 'black', fontSize: '24px' }}
          >
            إنضم الأن
          </Button>
        </Box>

        </Box>
        <Box sx={{ padding: 3 }}>
          <iframe
            width={isLandscape ? "560" : "100%"}
            height={isLandscape ? "315" : "200"}
            src="https://www.youtube.com/embed/0Bref7X7qTc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: '#0B7B5C', padding: 2, textAlign: 'center' }}>
        <Box display="flex" justifyContent="center" sx={{ marginTop: 2 }}>
          <IconButton href="https://www.facebook.com/kartooshbet" target="_blank" sx={{ color: 'white' }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://www.instagram.com/kartoosh.pro/" target="_blank" sx={{ color: 'white' }}>
            <InstagramIcon />
          </IconButton>
          <IconButton href="https://t.me/kartooshbetting" target="_blank" sx={{ color: 'white' }}>
            <LanguageIcon />
          </IconButton>
          <IconButton href="https://www.youtube.com/@Kartooshbet" target="_blank" sx={{ color: 'white' }}>
            <YouTubeIcon />
          </IconButton>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={isLandscape ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#FFD700',
            color: '#000000',
          }
        }}
      />
    </Box>
  );
};

export default PlayerInfo;
