import React, { createContext, useContext, useEffect, useState } from 'react';
import { ROLES } from '../constants/roles';
import { AuthContext } from './AuthContext';

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const [role, setRole] = useState(null);
  const [isAuthorizedForAgentsTree, setIsAuthorizedForAgentsTree] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (!loading && user?.agent?.role !== undefined) {
      const userRole = user.agent.role;
      setRole(userRole);

      setIsAuthorizedForAgentsTree(
        userRole === ROLES.OWNER ||
        userRole === ROLES.SUPER_ADMIN ||
        userRole === ROLES.ADMIN
      );

      // Check if the user is an OWNER
      setIsOwner(userRole === ROLES.OWNER);
    }
  }, [user, loading]);

  return (
    <RoleContext.Provider value={{ role, isAuthorizedForAgentsTree, isOwner }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
