import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, Switch, Button, List, ListItem, ListItemText, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const NotificationModal = ({ open, handleClose, setUnreadNotificationsCount }) => {
  const [notifications, setNotifications] = useState([]);
  const [showUnread, setShowUnread] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const fetchNotifications = async (start = 0, limit = 6, filter = {}) => {
    try {
      const response = await api.post('/notifications', {
        start,
        limit,
        filter,
      });
      setNotifications(response.data.result.records);
      setUnreadNotifications(response.data.result.totalUnreadUserNotificationsCount);
      setUnreadNotificationsCount(response.data.result.totalUnreadUserNotificationsCount); // Update the parent component state
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const response = await api.post('/notifications/markAllAsRead', { isAll: true }); // Ensure payload is passed
      if (response.data.status) {
        setUnreadNotifications(0);
        setUnreadNotificationsCount(0); // Update the parent component state
        fetchNotifications(); // Refresh notifications after marking all as read
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchNotifications();
    }
  }, [open]);

  const getPrimaryText = (notification) => {
    if (notification.type === '1') {
      const variables = JSON.parse(notification.variables);
      return `You have received a transfer of ${variables.title.amount}`;
    }
    return notification.titleKey;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" gutterBottom>Notifications</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Switch checked={showUnread} onChange={() => setShowUnread(!showUnread)} />
          <Typography>Show Unread</Typography>
          <Button onClick={markAllAsRead}>Mark All As Read</Button>
        </Box>
        <List>
          {notifications
            .filter(notification => !showUnread || notification.status === 'UNREAD')
            .map(notification => (
              <ListItem key={notification.id}>
                <ListItemText
                  primary={getPrimaryText(notification)}
                  secondary={notification.date}
                />
              </ListItem>
            ))}
        </List>
        <Box mt={2}>
          <Button
            onClick={handleClose}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
              color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default NotificationModal;
