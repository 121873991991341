import React from 'react';
import { Box } from '@mui/material';

const roleStyles = {
  0: { text: 'OWN', color: '#FFFFFF', background: '#027B5B' },
  1: { text: 'SUP', color: '#FFFFFF', background: '#6C727A' },
  2: { text: 'ADM', color: '#FFFFFF', background: '#F8C524' },
  3: { text: 'SHO', color: '#FFFFFF', background: '#202A35' },
  DEFAULT: { text: 'UNK', color: '#FFFFFF', background: '#E4E4E4' },
};
const RoleBadge = ({ role }) => {
    const { text, color, background } = roleStyles[role] || roleStyles.DEFAULT;
  
    return (
      <Box
        sx={{
          display: 'inline-block',
          padding: '4px 8px',
          borderRadius: '4px',
          fontSize: '12px',
          fontWeight: 'bold',
          color,
          backgroundColor: background,
          textAlign: 'center',
          minWidth: '32px',
        }}
      >
        {text}
      </Box>
    );
  };
  
  export default RoleBadge;