import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Layout from '../shared/Layout';
import api from '../../services/api';
import DynamicListComponent from '../shared/DynamicListComponent';
import {
  Container,
  Typography,
  Box,
  Button,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SmartButtonTwoToneIcon from '@mui/icons-material/SmartButtonTwoTone';
import PlayerActionsModal from './PlayerActionsModal';
import { useTranslation } from 'react-i18next';

const PlayersList = () => {
  const { user, checkAuth } = useContext(AuthContext);
  const [balance, setBalance] = useState(user ? user.balance : null);
  const [players, setPlayers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [sortConfig, setSortConfig] = useState({ title: null, status: 'asc' });
  const [formFilters, setFormFilters] = useState({
    playerUserName: '',
    playerId: '',
    registrationDateFrom: '',
    registrationDateTo: ''
  });
  const [apiFilters, setApiFilters] = useState({});
  const [balances, setBalances] = useState({}); // State to store balances
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [openPlayerActionsModal, setOpenPlayerActionsModal] = useState(false);
  const { t } = useTranslation(); // Initialize translation

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const formatCurrency = (value) => {
    return `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`;
  };
  const fetchBalance = async () => {
    try {
      const response = await api.get('/agent/balance', { withCredentials: true });
      const newBalance = response.data.balance;
      setBalance(formatCurrency(newBalance));
      checkAuth(); // Update the authentication state with the new balance
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };
  useEffect(() => {
    if (isPortrait) {
      setFiltersVisible(false);
    }
  }, [isPortrait]);

  useEffect(() => {
    const applyFiltersFromState = () => {
      if (location.state && location.state.filter) {
        const filterState = location.state.filter;
        const today = moment().utc().format('YYYY-MM-DD');
        const newFormFilters = {
          playerUserName: '',
          playerId: '',
          registrationDateFrom: filterState.date.from.split(' ')[0] || today,
          registrationDateTo: filterState.date.to.split(' ')[0] || today
        };
        const newApiFilters = {
          registrationDate: {
            from: filterState.date.from.split(' ')[0] || today,
            to: filterState.date.to.split(' ')[0] || today,
            action: 'between',
            valueLabel: `${filterState.date.from.split(' ')[0] || today} - ${filterState.date.to.split(' ')[0] || today}`
          }
        };
        setFormFilters(newFormFilters);
        setApiFilters(newApiFilters);
        fetchPlayers(newApiFilters, sortConfig);
      } else {
        setApiFilters({});
        fetchPlayers({}, sortConfig);
      }
    };

    applyFiltersFromState();
  }, [location.state]);

  useEffect(() => {
    if (!isInitialLoad) {
      fetchPlayers(apiFilters, sortConfig);
    } else {
      setIsInitialLoad(false);
    }
  }, [apiFilters, sortConfig, currentPage, pageSize]);

  const fetchPlayers = async (filters, sortConfig) => {
    try {
      const response = await api.post(
        '/players/list',
        {
          start: (currentPage - 1) * pageSize,
          limit: pageSize,
          filter: Object.keys(filters).length > 0 ? filters : {},
          sort: sortConfig.title ? sortConfig : undefined
        }
      );
      setPlayers(response.data.result.records);
      setTotalRecords(response.data.result.totalRecordsCount);
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };

  const fetchPlayerBalance = async (playerId) => {
    try {
      const response = await api.post('/players/getbalance', { playerId });
      setBalances((prevBalances) => ({
        ...prevBalances,
        [playerId]: formatCurrency(response.data.result[0].balance)
      }));
    } catch (error) {
      console.error('Error fetching player balance:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      [name]: value
    }));
  };

  const handleDateChange = (name, value) => {
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      [name]: moment(value).utc().format('YYYY-MM-DD')
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const cleanedFilters = cleanFilters(formFilters);
    setApiFilters(cleanedFilters);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    const resetFormFilters = {
      playerUserName: '',
      playerId: '',
      registrationDateFrom: '',
      registrationDateTo: ''
    };
    setFormFilters(resetFormFilters);
    setApiFilters(cleanFilters(resetFormFilters));
    setCurrentPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(totalRecords / pageSize)) {
      setCurrentPage(pageNumber);
      setGoToPage(''); // Clear the input after navigating
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const cleanFilters = (filters) => {
    const cleanedFilters = {};
    if (filters.playerUserName) {
      cleanedFilters.playerUserName = { action: 'like', value: filters.playerUserName, valueLabel: filters.playerUserName };
    }
    if (filters.playerId) {
      cleanedFilters.playerId = { action: '=', value: filters.playerId, valueLabel: filters.playerId };
    }
    if (filters.registrationDateFrom && filters.registrationDateTo) {
      cleanedFilters.registrationDate = {
        from: filters.registrationDateFrom,
        to: filters.registrationDateTo,
        action: 'between',
        valueLabel: `${filters.registrationDateFrom} - ${filters.registrationDateTo}`
      };
    }
    return cleanedFilters;
  };

  const handleSortChange = (key) => {
    let status = 'asc';
    if (sortConfig.title === key && sortConfig.status === 'asc') {
      status = 'desc';
    } else if (sortConfig.title === key && sortConfig.status === 'desc') {
      status = 'asc';
    }
    setSortConfig({ title: key, status });
  };

  const handleActionClick = (player) => {
    setSelectedPlayer(player);
    setOpenPlayerActionsModal(true);
  };

  const columns = [
    { key: 'playerId', label: t('playersList.columns.playerId') }, // Translate "Player ID"
    { key: 'username', label: t('playersList.columns.username') }, // Translate "Username"
    {
      key: 'balance',
      label: t('playersList.columns.balance'), // Translate "Balance"
      render: (row) => (
        balances[row.playerId] !== undefined ? balances[row.playerId] : (
          <IconButton onClick={() => fetchPlayerBalance(row.playerId)}>
            <VisibilityIcon />
          </IconButton>
        )
      )
    },
    {
      key: 'action',
      label: t('playersList.columns.action'), // Translate "Action"
      render: (row) => (
        <IconButton onClick={() => handleActionClick(row)}>
          <SmartButtonTwoToneIcon />
        </IconButton>
      )
    },
    { key: 'registrationDate', label: t('playersList.columns.registrationDate') } // Translate "Register Date"
  ];
  
  const filterFields = [
    {
      type: 'text',
      label: t('playersList.filters.username'), // Translate "Username"
      name: 'playerUserName'
    },
    {
      type: 'text',
      label: t('playersList.filters.playerId'), // Translate "Player ID"
      name: 'playerId'
    },
    {
      type: 'date',
      label: t('playersList.filters.registrationDateFrom'), // Translate "Registration Date From"
      name: 'registrationDateFrom'
    },
    {
      type: 'date',
      label: t('playersList.filters.registrationDateTo'), // Translate "Registration Date To"
      name: 'registrationDateTo'
    }
  ];
  

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4">{t('playersList.title')}</Typography> {/* Translate "Active Players" */}
          <Button onClick={() => setFiltersVisible(!filtersVisible)} variant="contained" color="primary">
            {filtersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {filtersVisible ? t('playersList.hideFilters') : t('playersList.showFilters')} {/* Translate "Hide Filters" / "Show Filters" */}
          </Button>
        </Box>
  
        <DynamicListComponent
          filters={formFilters}
          filterFields={filterFields}
          onFilterChange={handleFilterChange}
          onDateChange={handleDateChange}
          onFilterSubmit={handleFilterSubmit}
          onResetFilters={handleResetFilters}
          filtersVisible={filtersVisible}
          data={players}
          columns={columns}
          copiableFields={['playerId', 'username']}
          sortableFields={['playerId', 'username', 'registrationDate']}
          onRowClick={null} // Disable row click
          sortConfig={sortConfig}
          onSortChange={handleSortChange}
          pagination={{
            pageSize,
            currentPage,
            totalRecords,
            onPageChange: setCurrentPage,
            onPageSizeChange: handlePageSizeChange,
            onGoToPageChange: handleGoToPageChange,
            onGoToPage: handleGoToPage,
            onNextPage: handleNextPage,
            onPreviousPage: handlePreviousPage,
            goToPage,
            setGoToPage
          }}
        />
      </Container>
      <PlayerActionsModal
        open={openPlayerActionsModal}
        handleClose={() => setOpenPlayerActionsModal(false)}
        formatCurrency={formatCurrency}
        fetchBalance={fetchBalance}
        player={selectedPlayer}
      />
    </Layout>
  );
  
};

export default PlayersList;
