import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import translation hook

const TotalOpenBets = ({ count, sx }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(); // Initialize translation

  const handleCardClick = () => {
    history.push({
      pathname: '/bets',
      state: {
        filter: {
          betStatus: {
            action: 'in',
            value: [1],
            valueLabel: t('total_open_bets.accepted'), // Translate "Accepted"
          },
        },
      },
    });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <ReceiptLongIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box
            sx={{
              ...(i18n.language === 'ar' ? { mr: 2 } : { ml: 2 }), // Adjust spacing for RTL
            }}
          >
            <Typography variant="h6">{t('total_open_bets.title')}</Typography> {/* Translate "Total Open Bets" */}
            <Typography variant="h4">{count}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalOpenBets;
