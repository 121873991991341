import React, { useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  InputAdornment,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import api from '../../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const ChangePasswordModal = ({ open, handleClose, user, setSnackbarOpen }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordChecklist, setPasswordChecklist] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const validatePassword = (password) => {
    const length = password.length >= 6;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    setPasswordChecklist({ length, uppercase, lowercase, number, specialChar });
    return length && uppercase && lowercase && number && specialChar;
  };

  const handleSubmit = async () => {
    if (!validatePassword(newPassword)) {
      setPasswordError('Password does not meet requirements');
      return;
    }

    if (newPassword !== repeatedPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      const response = await api.post('/agent/change-password', {
        oldPassword,
        newPassword,
        repeatedPassword
      });

      if (response.data.status) {
        handleClose();
        setSnackbarOpen(response.data.notification[0].content);
      } else {
        setPasswordError('Failed to change password');
      }
    } catch (error) {
      setPasswordError('Failed to change password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Password
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TextField
              label="Old Password"
              type={showOldPassword ? 'text' : 'password'}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              fullWidth
              margin="normal"
              error={Boolean(passwordError)}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Typography variant="subtitle1">Password must contain:</Typography>
              <FormHelperText error={!passwordChecklist.length} sx={{ color: passwordChecklist.length ? 'green' : 'inherit' }}>
                {passwordChecklist.length ? <CheckIcon /> : ''} At least 6 characters
              </FormHelperText>
              <FormHelperText error={!passwordChecklist.uppercase} sx={{ color: passwordChecklist.uppercase ? 'green' : 'inherit' }}>
                {passwordChecklist.uppercase ? <CheckIcon /> : ''} At least one uppercase letter
              </FormHelperText>
              <FormHelperText error={!passwordChecklist.lowercase} sx={{ color: passwordChecklist.lowercase ? 'green' : 'inherit' }}>
                {passwordChecklist.lowercase ? <CheckIcon /> : ''} At least one lowercase letter
              </FormHelperText>
              <FormHelperText error={!passwordChecklist.number} sx={{ color: passwordChecklist.number ? 'green' : 'inherit' }}>
                {passwordChecklist.number ? <CheckIcon /> : ''} At least one number
              </FormHelperText>
              <FormHelperText error={!passwordChecklist.specialChar} sx={{ color: passwordChecklist.specialChar ? 'green' : 'inherit' }}>
                {passwordChecklist.specialChar ? <CheckIcon /> : ''} At least one special character
              </FormHelperText>
            </Box>
            <TextField
              label="Repeat New Password"
              type={showRepeatedPassword ? 'text' : 'password'}
              value={repeatedPassword}
              onChange={(e) => setRepeatedPassword(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowRepeatedPassword(!showRepeatedPassword)}
                    >
                      {showRepeatedPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{ mt: 2 }}
            >
              Confirm
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
