import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import api from '../../services/api';
import { useTranslation } from 'react-i18next'; // Import translation hook

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const PlayerChangePasswordModal = ({ open, handleClose, playerId }) => {
  const { t } = useTranslation(); // Initialize translation
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  useEffect(() => {
    if (!open) {
      setPassword('');
    }
  }, [open]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    if (password.length < 6) {
      setSnackbarMessage(t('playerChangePassword.passwordTooShort'));
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await api.post('/players/changepassword', {
        playerId,
        password,
      });

      if (response.data.result) {
        setSnackbarMessage(t('playerChangePassword.success'));
      } else {
        setSnackbarMessage(t('playerChangePassword.failure'));
      }
    } catch (error) {
      console.error(t('playerChangePassword.error'), error);
      setSnackbarMessage(t('playerChangePassword.failure'));
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('playerChangePassword.title')}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label={t('playerChangePassword.newPassword')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('playerChangePassword.togglePasswordVisibility')}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="success"
              fullWidth
              disabled={loading}
              sx={{
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : t('playerChangePassword.confirm')}
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="warning"
              fullWidth
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                },
              }}
            >
              {t('playerChangePassword.close')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: isLandscape ? 'bottom' : 'top', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor: process.env.REACT_APP_SECONDARY_COLOR, // Yellow background
            color: '#000000', // Black text color
            fontWeight: 'bold',
          },
        }}
      />
    </>
  );
};

export default PlayerChangePasswordModal;
