import React, { useContext, useEffect, useState } from 'react';
import api from '../../services/api';
import { AuthContext } from '../../context/AuthContext';
import {
  Container,
  Grid
} from '@mui/material';
import Layout from '../shared/Layout';
import moment from 'moment';
import DailyPlayerTransactions from './DailyPlayerTransactions';
import TotalRegisteredPlayersToday from './TotalRegisteredPlayersToday';
import TotalOpenBets from './TotalOpenBets';
import TotalPlayersCount from './TotalPlayersCount';
import { useTranslation } from 'react-i18next';


const Dashboard = () => {
  const { t } = useTranslation();

  const { user, checkAuth } = useContext(AuthContext);
  const [balance, setBalance] = useState(user ? user.balance : null);
  const [widgetsData, setWidgetsData] = useState({
    totalRegisteredPlayersToday: 0,
    totalPlayersCount: 0,
    totalOpenBets: 0,
  });
  const [loading, setLoading] = useState(true);
  const formatCurrency = (value) => {
    return `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`;
  };
  const fetchBalance = async () => {
    try {
      const response = await api.get('/agent/balance', { withCredentials: true });
      const newBalance = response.data.balance;
      setBalance(newBalance);
      checkAuth();
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const fetchWidgetsData = async () => {
    try {
      const response = await api.post('/dashboard/getwidgetsdata', {
        filter: {
          date: { from: '2024/07/01', to: '2024/07/06' }
        }
      }, { withCredentials: true });

      const data = response.data.result.widgets;

      setWidgetsData({
        totalRegisteredPlayersToday: parseInt(data.getNewRegisteredPlayersCount.count, 10),
        totalPlayersCount: parseInt(data.getNewRegisteredPlayersCount.totalPlayers, 10),
        totalOpenBets: data.totalOpenBets.count ? parseInt(data.totalOpenBets.count, 10) : 0,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error fetching widgets data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBalance();
    fetchWidgetsData();
  }, []); // Empty dependency array to ensure this runs only once

  if (!user || loading) return <div>Loading...</div>;

  return (
    <Layout>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <DailyPlayerTransactions sx={{ height: '100%' }} formatCurrency = {formatCurrency} from = {moment().startOf('day').toDate()} to = {moment().add(1, 'days').startOf('day').toDate()} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalRegisteredPlayersToday 
              count={widgetsData.totalRegisteredPlayersToday}
              sx={{ height: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalPlayersCount 
              totalPlayers={widgetsData.totalPlayersCount}
              sx={{ height: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalOpenBets 
              count={widgetsData.totalOpenBets}
              sx={{ height: '100%' }}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Dashboard;
