import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton, // Import IconButton here
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarComponent from '../shared/SnackbarComponent';
import api from '../../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  overflowY: 'auto',
  maxHeight: '90vh',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  p: 3,
};

const RiskSettingsModal = ({ open, handleClose, agentId }) => {
  const [loading, setLoading] = useState(false);
  const [riskSettings, setRiskSettings] = useState(null);
  const [ruleExists, setRuleExists] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });

  useEffect(() => {
    if (open && agentId) {
      fetchRiskSettings();
      fetchTemplates();
    }
  }, [open, agentId]);

  const fetchRiskSettings = async () => {
    setLoading(true);
    try {
      const response = await api.post('/agent/limits/rule/getbyagent', { agentId });
      setRiskSettings(response.data);
      setRuleExists(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const defaultSettings = await fetchDefaultSettings();
        setRiskSettings(defaultSettings);
        setRuleExists(false);
      } else {
        console.error('Error fetching risk settings:', error);
        setSnackbar({ open: true, message: 'Failed to fetch risk settings.', type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDefaultSettings = async () => {
    try {
      const response = await api.get('/agent/limits/default');
      return response.data;
    } catch (error) {
      console.error('Error fetching default settings:', error);
      setSnackbar({ open: true, message: 'Failed to fetch default settings.', type: 'error' });
      return {};
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await api.post('/agent/limits/template/list', {});
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setSnackbar({ open: true, message: 'Failed to fetch templates.', type: 'error' });
    }
  };

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
    const selectedTemplate = templates.find((template) => template._id === templateId);
    if (selectedTemplate) {
      const { _id, templateName, __v, ...templateFields } = selectedTemplate;
      setRiskSettings({ ...riskSettings, ...templateFields });
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const { _id, __v, agentId: _, ...filteredRiskSettings } = riskSettings;

    try {
      if (ruleExists) {
        await api.post('/agent/limits/rule/update', { _id, ...filteredRiskSettings });
        setSnackbar({ open: true, message: 'Risk settings updated successfully!', type: 'success' });
      } else {
        await api.post('/agent/limits/rule/insert', { agentId, ...filteredRiskSettings });
        setSnackbar({ open: true, message: 'Risk settings created successfully!', type: 'success' });
      }
      handleClose();
    } catch (error) {
      console.error('Error saving risk settings:', error);
      setSnackbar({ open: true, message: 'Failed to save risk settings.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await api.post('/agent/limits/rule/delete', { _id: riskSettings._id });
      setSnackbar({ open: true, message: 'Risk settings deleted successfully!', type: 'success' });
      setRiskSettings(await fetchDefaultSettings());
      setRuleExists(false);
    } catch (error) {
      console.error('Error deleting risk settings:', error);
      setSnackbar({ open: true, message: 'Failed to delete risk settings.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', type: '' });
  };

  if (loading) {
    return (
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" fontWeight="bold">
              Risk Settings
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Card sx={{ mb: 3, boxShadow: 2, p: 2 }}>
            <Typography variant="body1" gutterBottom>
              {ruleExists
                ? 'A rule record was found for this agent.'
                : 'No rule record found for this agent. Showing default settings.'}
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Assign Limit Template</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => handleTemplateSelect(e.target.value)}
              >
                {templates.map((template) => (
                  <MenuItem key={template._id} value={template._id}>
                    {template.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Card>
          {riskSettings &&
            Object.keys(riskSettings)
              .filter((key) => key !== '_id' && key !== '__v' && key !== 'agentId')
              .map((key) => (
                <TextField
                  key={key}
                  label={key.replace(/([A-Z])/g, ' $1')}
                  type="number"
                  value={riskSettings[key]}
                  onChange={(e) =>
                    setRiskSettings({ ...riskSettings, [key]: Number(e.target.value) || 0 })
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  size="small"
                />
              ))}
          <Box display="flex" justifyContent="space-between" mt={3} gap={2}>
            {ruleExists && (
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                sx={{ width: '45%', fontWeight: 'bold' }}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ width: ruleExists ? '45%' : '100%', fontWeight: 'bold' }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <SnackbarComponent
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default RiskSettingsModal;
