import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';
import SnackbarComponent from '../shared/SnackbarComponent';
import api from '../../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const ConfirmBlockUnblockModal = ({ open, handleClose, agentId, actionType, onActionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (open) {
      setLoading(false);
    }
  }, [open]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const response = await api.post('/agent/change-status', {
        affiliateId: Number(agentId),
        statusId: actionType === 'Block' ? 3 : 2,
      });

      if (response.data.result === 1) {
        setSnackbarMessage(`Agent ${actionType.toLowerCase()}ed successfully.`);
        onActionComplete();
      } else {
        setSnackbarMessage(response.data.message || 'Failed to update agent status.');
      }
    } catch (error) {
      setSnackbarMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Box sx={style}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography variant="h6" textAlign="center">
                Are you sure you want to {actionType.toLowerCase()} this agent?
              </Typography>
              <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ width: 'calc(50% - 8px)', borderColor: '#6c727a', color: '#6c727a' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirm}
                  sx={{
                    width: 'calc(50% - 8px)',
                    backgroundColor: actionType === 'Block' ? '#B71C1C' : '#1E88E5',
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: actionType === 'Block' ? '#8B0000' : '#1565C0',
                    },
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default ConfirmBlockUnblockModal;
