import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Snackbar,
  useMediaQuery
} from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // New Money Transfer Icon
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoneyTransferModal from './MoneyTransferModal';
import PlayerChangePasswordModal from './PlayerChangePasswordModal';
import PlayerRiskSettingsModal from './PlayerRiskSettingsModal'; // Import new modal
import ConfirmPlayerBlockModal from './ConfirmPlayerBlockModal';
import api from '../../services/api';
import { useRole } from '../../context/RoleContext';
import { useTranslation } from 'react-i18next'; // Import translation hook

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

const PlayerActionsModal = ({ open, handleClose, formatCurrency, fetchBalance, player }) => {
  const [openMoneyTransferModal, setOpenMoneyTransferModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openRiskSettingsModal, setOpenRiskSettingsModal] = useState(false);
  const [confirmBlockModalOpen, setConfirmBlockModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [initialUsername, setInitialUsername] = useState('');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const { isOwner } = useRole();
  const { t } = useTranslation(); // Initialize translation

  useEffect(() => {
    if (player) {
      setInitialUsername(`${player.username} - ID: ${player.playerId}`);
    }
  }, [player]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(`Copied: ${text}`);
    setSnackbarOpen(true);
  };

  const handleTransferMoney = () => {
    setOpenMoneyTransferModal(true);
  };

  const handleCloseMoneyTransferModal = () => {
    setOpenMoneyTransferModal(false);
  };

  const handleChangePassword = () => {
    setOpenChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleRiskSettings = () => {
    setOpenRiskSettingsModal(true);
  };

  const handleCloseRiskSettingsModal = () => {
    setOpenRiskSettingsModal(false);
  };

  const handleBlockPlayer = () => {
    setConfirmBlockModalOpen(true);
  };

  const handleConfirmBlockPlayer = async () => {
    try {
      const response = await api.post('/players/block', { playerId: player.playerId });

      if (response.data.result) {
        setSnackbarMessage('Player blocked successfully');
      } else {
        setSnackbarMessage('Failed to block player');
      }
    } catch (error) {
      setSnackbarMessage('Failed to block player');
    } finally {
      setSnackbarOpen(true);
      setConfirmBlockModalOpen(false);
    }
  };

  const handleCloseConfirmBlockModal = () => {
    setConfirmBlockModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('playerActions.title')} {/* Translated "Player Actions" */}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {player && (
            <Box mb={2}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography
                  variant="body1"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleCopyToClipboard(player.playerId)}
                >
                  <strong>{t('playerActions.playerId')}:</strong> {player.playerId}
                </Typography>
                <IconButton onClick={() => handleCopyToClipboard(player.playerId)} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleCopyToClipboard(player.username)}
                >
                  <strong>{t('playerActions.username')}:</strong> {player.username}
                </Typography>
                <IconButton onClick={() => handleCopyToClipboard(player.username)} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          )}
          <Button
            onClick={handleTransferMoney}
            startIcon={<MonetizationOnIcon />} // New Money Transfer Icon
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1, // Add gap between icon and text
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
            disabled={!player}
          >
            {t('playerActions.moneyTransfer')} {/* Translated "Money Transfer" */}
          </Button>
          <Button
            onClick={handleChangePassword}
            startIcon={<LockIcon />}
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
            disabled={!player}
          >
            {t('playerActions.changePassword')} {/* Translated "Change Password" */}
          </Button>
          {isOwner && (
            <Button
              onClick={handleRiskSettings}
              startIcon={<SettingsIcon />}
              fullWidth
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                backgroundColor: '#027B5B',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025F45',
                },
              }}
              disabled={!player}
            >
              {t('playerActions.changeRiskSettings')} {/* Translated "Change Risk Settings" */}
            </Button>
          )}
          <Button
            onClick={handleBlockPlayer}
            startIcon={<BlockIcon />}
            fullWidth
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              backgroundColor: '#B71C1C',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#8B0000',
              },
            }}
            disabled={!player}
          >
            {t('playerActions.block')} {/* Translated "Block" */}
          </Button>
          <Box mt={2}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                },
              }}
            >
              {t('playerActions.close')} {/* Translated "Close" */}
            </Button>
          </Box>
        </Box>
      </Modal>
      <MoneyTransferModal
        open={openMoneyTransferModal}
        handleClose={handleCloseMoneyTransferModal}
        setOpenMoneyTransferModal={setOpenMoneyTransferModal}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
        initialUsername={initialUsername}
      />
      <PlayerChangePasswordModal
        open={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
        playerId={player?.playerId}
      />
      <PlayerRiskSettingsModal
        open={openRiskSettingsModal}
        handleClose={handleCloseRiskSettingsModal}
        playerId={player?.playerId}
      />
      <ConfirmPlayerBlockModal
        open={confirmBlockModalOpen}
        handleClose={handleCloseConfirmBlockModal}
        playerId={player?.playerId}
        onActionComplete={handleConfirmBlockPlayer}
      />
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={
          isLandscape
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'center' }
        }
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
            color: process.env.REACT_APP_PRIMARY_COLOR,
          },
        }}
      />
    </>
  );
};

export default PlayerActionsModal;
