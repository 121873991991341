import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Box,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import usFlag from '../../assets/us-flag.png';
import frFlag from '../../assets/fr-flag.png';
import tnFlag from '../../assets/tn-flag.png';
import ChangePasswordModal from './ChangePasswordModal';
import SnackbarComponent from '../shared/SnackbarComponent';
import { useTranslation } from 'react-i18next'; // Import the translation hook

const AccountMenu = ({ user, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const { i18n, t } = useTranslation(); // Access i18n instance and t function

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLanguageAnchorEl(null);
  };

  const handleLanguageMenu = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(null);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(user.currentUser.username);
    setSnackbarMessage(t('usernameCopied')); // Use translation key
    setSnackbarOpen(true);
  };

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
    handleClose();
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleLanguageSelect = (language) => {
    i18n.changeLanguage(language); // Switch the language
    setSnackbarMessage(
      t('languageChanged', {
        language: language === 'fr' ? t('french') : language === 'ar' ? t('arabic') : t('english'),
      })
    );
    setSnackbarOpen(true);
    handleCloseLanguageMenu();
  };

  const getFlag = () => {
    switch (i18n.language) {
      case 'fr':
        return frFlag;
      case 'ar':
        return tnFlag;
      default:
        return usFlag;
    }
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: '12px',
            paddingTop: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
          <AccountCircle sx={{ fontSize: 60, color: 'gray' }} />
          <MenuItem
            onClick={handleCopyToClipboard}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'center',
              width: '100%',
              backgroundColor: '#f0f0f0',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            {user.currentUser.username}
            <ContentCopyIcon sx={{ fontSize: 16 }} />
          </MenuItem>
        </Box>
        <MenuItem onClick={handleOpenChangePasswordModal}>{t('changePassword')}</MenuItem>
        <MenuItem onClick={handleLanguageMenu}>
          {i18n.language === 'en' && t('english')}
          {i18n.language === 'fr' && t('french')}
          {i18n.language === 'ar' && t('arabic')}
          <img
            src={getFlag()}
            alt="Current Language"
            style={{
              width: 24,
              ...(i18n.language === 'ar' ? { marginRight: 8 } : { marginLeft: 8 }), // Adjust margin dynamically
            }}
          />

        </MenuItem>

        <Menu
          anchorEl={languageAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(languageAnchorEl)}
          onClose={handleCloseLanguageMenu}
        >
          <MenuItem onClick={() => handleLanguageSelect('en')}>
            <ListItemIcon>
              <img src={usFlag} alt={t('english')} style={{ width: 24 }} />
            </ListItemIcon>
            {t('english')}
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('fr')}>
            <ListItemIcon>
              <img src={frFlag} alt={t('french')} style={{ width: 24 }} />
            </ListItemIcon>
            {t('french')}
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('ar')}>
            <ListItemIcon>
              <img src={tnFlag} alt={t('arabic')} style={{ width: 24 }} />
            </ListItemIcon>
            {t('arabic')}
          </MenuItem>
        </Menu>
        <MenuItem onClick={logout} sx={{ color: 'red' }}>
          {t('logout')}
        </MenuItem>
      </Menu>
      <ChangePasswordModal
        open={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
        user={user}
        setSnackbarOpen={(message) => {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
        }}
      />
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AccountMenu;
