import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base URL here
  withCredentials: true,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Log out the user and redirect to login
      try {
        await api.post('/auth/logout');
      } catch (logoutError) {
        console.error('Error during logout:', logoutError);
      }
      window.location.href = '/login';
      return Promise.reject('Session expired. Redirecting to login.');
    }
    return Promise.reject(error);
  }
);

export default api;
