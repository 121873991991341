import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const history = useHistory();

  const login = async (username, password, token) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, { username, password, token }, { withCredentials: true });
      setUser(response.data);
      history.push('/');
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`, {}, { withCredentials: true });
      setUser(null);
      history.push('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const checkAuth = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/checkAuth`, { withCredentials: true });
      if (response.data.authenticated) {
        setUser(response.data);
      } else {
        console.log('not authenticated')
        setUser(null);
        history.push('/login'); // Redirect to login
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setUser(null);
        history.push('/login'); // Redirect to login
      } else {
        console.error('CheckAuth error:', error);
      }
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <AuthContext.Provider value={{ user, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
